import sstorage from "assets/images/sstorage.png";
import resumeoptimizer from "assets/images/resumeoptimizer.png";
import breadbutter from "assets/images/breadbutter.png";

export default [
  {
    title: "Software",
    description: "Software As A Service",
    items: [
      {
        image: sstorage,
        name: "Shared storage",
        description: "Shared storage for your home and office",
        route: "http://sstorage-frontend.s3-website-us-east-1.amazonaws.com/",
      },
      {
        image: resumeoptimizer,
        name: "Resume optimizer",
        description: "AI based resume optimizer",
        route: "https://github.com/rishabbahal9/AI-Resume-Optimizer",
      },
      {
        image: breadbutter,
        name: "Breadbutter.dev",
        description: "Grocery management system",
        // pro: true,
        route: "https://breadbutter.dev/",
      },
    ],
  },
  {
    title: "AI-ML",
    description: "15+ Machine learning models",
    items: [
      {
        image: resumeoptimizer,
        name: "Resume optimizer",
        description: "AI based resume optimizer",
        route: "https://github.com/rishabbahal9/AI-Resume-Optimizer",
      },
    ],
  },
];
